/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import type { LngLat } from '@yandex/ymaps3-types';
import { useRouter } from 'next/navigation';
import { YMapMarker } from 'ymap3-components';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import styles from './custom-cluster-mobile.module.css';

export const CustomClusterMobile = ({
    isPartnerModal,
    features,
    coordinates,
    setAllAddressPartnerId,
}: {
    isPartnerModal: boolean;
    features: any;
    coordinates: LngLat;
    setAllAddressPartnerId?: (partnerId: number) => void;
}) => {
    const [openPopup, setOpenPopup] = useState(false);
    const router = useRouter();

    const handleShowAllAddress = (id: number) => {
        setAllAddressPartnerId?.(id);
    };

    return (
        <>
            <YMapMarker key={`${features[0].id}-${features.length}`} coordinates={coordinates}>
                <div
                    onMouseEnter={() => setOpenPopup(true)}
                    style={{
                        borderRadius: '50%',
                        background: 'linear-gradient(0deg, #FD6B00 0%, #EA6300 37.53%, #DA5C00 67.22%, #C95500 100%)',
                        padding: '4px',
                        width: 32,
                        height: 32,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            background: 'var(--color-background-primary)',
                            color: 'var(--color-text-primary)',
                            borderRadius: '50%',
                            width: 24,
                            height: 24,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {features.length}
                    </span>
                </div>
            </YMapMarker>
            {openPopup && (
                <div
                    onMouseLeave={() => {
                        setOpenPopup(false);
                    }}
                >
                    <YMapMarker key={`${features[0].id}-${features.length}`} coordinates={coordinates} blockBehaviors={true} zIndex={3_000}>
                        <div
                            style={{
                                borderRadius: '50%',
                                background: 'linear-gradient(0deg, #FD6B00 0%, #EA6300 37.53%, #DA5C00 67.22%, #C95500 100%)',
                                padding: '4px',
                                width: 32,
                                height: 32,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <span
                                style={{
                                    background: 'var(--color-background-primary)',
                                    color: 'var(--color-text-primary)',
                                    borderRadius: '50%',
                                    width: 24,
                                    height: 24,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {features.length}
                            </span>
                        </div>
                        <Drawer
                            isOpen={openPopup}
                            placement='bottom'
                            autoFocus={false}
                            onClose={() => setOpenPopup(false)}
                            returnFocusOnClose={false}
                        >
                            <DrawerOverlay />

                            <DrawerContent borderTopLeftRadius={'16px'} borderTopRightRadius={'16px'} border={'none'}>
                                <DrawerBody borderBottomWidth={'none'} pt='20px' pl='16px' pr='16px' pb='20px'>
                                    {features.map((item: any) => (
                                        <div key={item.id} className={styles['partner-container']}>
                                            <TypographyCore size='m' view='bold' className='mb-8'>
                                                {item.partner.name}
                                            </TypographyCore>
                                            <TypographyCore size='m' view='regular' color='secondary' className='mb-8'>
                                                {item.address}
                                            </TypographyCore>
                                            {isPartnerModal ? null : (
                                                <ButtonCore
                                                    view='primary'
                                                    size='large'
                                                    onClick={() => router.push(`/partner/${item.partner.id}`)}
                                                    className={`${styles['button-width']} mt-4 mb-4`}
                                                >
                                                    Перейти к каталогу
                                                </ButtonCore>
                                            )}
                                            <ButtonCore
                                                view='secondary'
                                                size='large'
                                                onClick={() => handleShowAllAddress(item.partner.id)}
                                                className={`${styles['button-width']}`}
                                            >
                                                Показать все адреса
                                            </ButtonCore>
                                        </div>
                                    ))}
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    </YMapMarker>
                </div>
            )}
        </>
    );
};
