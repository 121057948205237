/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useCallback, useEffect, useState } from 'react';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import type { LngLat } from '@yandex/ymaps3-types';
import { useRouter } from 'next/navigation';
import { YMap, YMapComponentsProvider, YMapCustomClusterer, YMapDefaultFeaturesLayer, YMapDefaultSchemeLayer } from 'ymap3-components';

import { useAppSelector } from '~/lib/hooks';
import type { Order } from '~/lib/order/api';
import type { Partner } from '~/lib/partners/api';
import { useGetPartnersSalePointsQuery } from '~/lib/partners/api';
import { getCenter } from '~/utils/mapCenter';

import { AllAddressPopup } from './all-address-popup';
import { CustomClusterMobile } from './custom-cluster-mobile';
import { CustomMarkerMobile } from './custom-marker-mobile';

import styles from './map-mobile-modal.module.css';

export const MapMobileModal = ({
    onClose,
    partner,
    partnerSalePoints,
    returnUrl = '/',
}: {
    onClose: () => void;
    partner?: Partner;
    partnerSalePoints?: Order['sale_points'];
    returnUrl?: string;
}) => {
    const latSelectedCity = useAppSelector((state) => state.user.data.address.lat ?? 0);
    const lonSelectedCity = useAppSelector((state) => state.user.data.address.lon ?? 0);
    const selectedCityId = useAppSelector((state) => state.user.data.address.id);
    const router = useRouter();
    const [allAddressPartnerId, setAllAddressPartnerId] = useState<null | number>(null);

    const isPartnerModal = !!partner;

    const { salePoints = [] } = useGetPartnersSalePointsQuery(
        {
            $filter: `${selectedCityId ? `&city_ids=${selectedCityId}` : ''}${isPartnerModal ? `&partner_ids=${partner.id}` : ''}`,
        },
        {
            selectFromResult: ({ data }) => ({
                salePoints: data?.data.items
                    .map((point, index) => ({
                        type: 'Feature',
                        id: `${point.partner.name}-${index}`,
                        partner: point.partner,
                        address: point.address,
                        geometry: {
                            type: 'Point',
                            coordinates: [point.lon, point.lat] as LngLat,
                        },
                    }))
                    .filter((feature) => {
                        const coordinates = feature.geometry.coordinates;
                        return partnerSalePoints
                            ? partnerSalePoints?.some((address) => coordinates[0] === address.lon && coordinates[1] === address.lat)
                            : true;
                    }),
            }),
        },
    );

    const marker = useCallback((feature: any) => {
        return <CustomMarkerMobile isPartnerModal={isPartnerModal} feature={feature} setAllAddressPartnerId={setAllAddressPartnerId} />;
    }, []);

    const cluster = useCallback((coordinates: LngLat, features: any[]) => {
        return (
            <CustomClusterMobile
                isPartnerModal={isPartnerModal}
                coordinates={coordinates}
                features={features}
                setAllAddressPartnerId={setAllAddressPartnerId}
            />
        );
    }, []);

    const handleRoutBack = () => {
        router.push(returnUrl);
    };

    useEffect(() => {
        window.onpopstate = () => handleRoutBack();
    });

    return (
        <Drawer isOpen={true} onClose={onClose} size='full' autoFocus={false} returnFocusOnClose={false}>
            <DrawerOverlay />

            <DrawerContent border={'none'} p={0}>
                <DrawerCloseButton zIndex={1000} color='rgba(207, 207, 207, 1)' backgroundColor={'rgba(255, 255, 255, 1)'} />
                <DrawerBody borderBottomWidth={'none'} p={0}>
                    <div className={styles['container-map']}>
                        <YMapComponentsProvider apiKey={'fda85a65-b535-4ad0-b669-6d093411767b'}>
                            <YMap
                                key='map'
                                location={{
                                    center:
                                        partner && salePoints.length
                                            ? getCenter(salePoints.map((point) => ({ coordinates: point.geometry.coordinates })))
                                            : [lonSelectedCity, latSelectedCity],
                                    zoom: salePoints.length > 1 ? 11.5 : 13,
                                }}
                                mode='vector'
                            >
                                <YMapDefaultSchemeLayer />
                                <YMapDefaultFeaturesLayer />
                                {/* @ts-ignore */}
                                <YMapCustomClusterer gridSize={64} marker={marker} cluster={cluster} features={salePoints} />
                            </YMap>
                        </YMapComponentsProvider>
                    </div>
                    {allAddressPartnerId ? (
                        <AllAddressPopup
                            filteredSalePoints={salePoints.filter((feature) => {
                                return allAddressPartnerId ? feature.partner.id === allAddressPartnerId : true;
                            })}
                            onClose={() => setAllAddressPartnerId(null)}
                        />
                    ) : null}
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};
