import type { LngLat } from '@yandex/ymaps3-types';

type Point = { coordinates: LngLat };

export const getCenter = (points: Point[]): LngLat => {
    const totalPoints = points.length;

    // Переменные для суммирования координат в 3D
    let x = 0,
        y = 0,
        z = 0;

    for (const point of points) {
        const [lon, lat] = point.coordinates;

        // Перевод широты и долготы из градусов в радианы
        const latRad = (lat * Math.PI) / 180;
        const lonRad = (lon * Math.PI) / 180;

        // Преобразование в 3D-картезианские координаты
        x += Math.cos(latRad) * Math.cos(lonRad);
        y += Math.cos(latRad) * Math.sin(lonRad);
        z += Math.sin(latRad);
    }

    // Вычисление среднего значения для координат
    x /= totalPoints;
    y /= totalPoints;
    z /= totalPoints;

    // Преобразование обратно в географические координаты
    const centralLon = Math.atan2(y, x) * (180 / Math.PI);
    const centralLat = Math.atan2(z, Math.sqrt(x * x + y * y)) * (180 / Math.PI);

    return [centralLon, centralLat];
};
