/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Box, Drawer, DrawerBody, DrawerContent, DrawerOverlay } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';
import { YMapMarker } from 'ymap3-components';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import styles from './custom-marker-mobile.module.css';

export const CustomMarkerMobile = ({
    isPartnerModal,
    feature,
    setAllAddressPartnerId,
    showAllAddress = true,
}: {
    isPartnerModal: boolean;
    feature: any;
    setAllAddressPartnerId?: (partnerId: number) => void;
    showAllAddress?: boolean;
}) => {
    const [openPopup, setOpenPopup] = useState(false);
    const router = useRouter();

    const handleShowAllAddress = () => {
        setAllAddressPartnerId?.(feature.partner.id);
    };

    return (
        <>
            <YMapMarker key={feature.id} coordinates={feature.geometry.coordinates}>
                <div className={styles['icon-marker']} onMouseEnter={() => setOpenPopup(true)}>
                    <Box zIndex={10}>
                        <img src='/map-marker.png' alt='map-marker' />
                    </Box>
                    <Box
                        background={'rgba(255, 255, 255, 1)'}
                        borderRadius={'8px'}
                        pt='4px'
                        pl='12px'
                        pr='12px'
                        pb='4px'
                        className={styles['point-name-container']}
                    >
                        <TypographyCore view='medium' size='s' className={styles['point-name']}>
                            {feature.partner.name}
                        </TypographyCore>
                    </Box>
                </div>
            </YMapMarker>
            {openPopup && (
                <div
                    onMouseLeave={() => {
                        setOpenPopup(false);
                    }}
                >
                    <YMapMarker key={feature.id} coordinates={feature.geometry.coordinates} blockBehaviors={true} zIndex={3_000}>
                        <div className={styles['icon-marker']}>
                            <Box zIndex={10}>
                                <img src='/map-marker.png' alt='map-marker' />
                            </Box>
                            <Box
                                background={'rgba(255, 255, 255, 1)'}
                                borderRadius={'8px'}
                                pt='4px'
                                pl='12px'
                                pr='12px'
                                pb='4px'
                                className={styles['point-name-container']}
                            >
                                <TypographyCore view='medium' size='s' className={styles['point-name']}>
                                    {feature.partner.name}
                                </TypographyCore>
                            </Box>
                        </div>
                        <Drawer
                            isOpen={openPopup}
                            placement='bottom'
                            autoFocus={false}
                            onClose={() => setOpenPopup(false)}
                            returnFocusOnClose={false}
                        >
                            <DrawerOverlay />

                            <DrawerContent borderTopLeftRadius={'16px'} borderTopRightRadius={'16px'} border={'none'}>
                                <DrawerBody borderBottomWidth={'none'} pt='20px' pl='16px' pr='16px' pb='20px'>
                                    <TypographyCore size='m' view='bold' className='mb-8'>
                                        {feature.partner.name}
                                    </TypographyCore>
                                    <TypographyCore size='m' view='regular' color='secondary' className='mb-8'>
                                        {feature.address}
                                    </TypographyCore>
                                    {isPartnerModal ? null : (
                                        <ButtonCore
                                            view='primary'
                                            size='large'
                                            onClick={() => router.push(`/partner/${feature.partner.id}`)}
                                            className={`${styles['button-width']} mt-4 mb-4`}
                                        >
                                            Перейти к каталогу
                                        </ButtonCore>
                                    )}
                                    {showAllAddress ? (
                                        <ButtonCore
                                            view='secondary'
                                            size='large'
                                            onClick={handleShowAllAddress}
                                            className={`${styles['button-width']}`}
                                        >
                                            Показать все адреса
                                        </ButtonCore>
                                    ) : null}
                                </DrawerBody>
                            </DrawerContent>
                        </Drawer>
                    </YMapMarker>
                </div>
            )}
        </>
    );
};
